import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import DataGrid from "../../components/DataGrid";
import useRequest from "../../hooks/useRequest";
import { FILES_HISTORY } from "../../data/APIs";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import format from "../../utils/ISOToReadable";
import { IconButton, TextField } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { InputField } from "../../features/form";
import { Stack } from "@mui/system";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";

const ImportRegistry = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const exportRegistryLang = useSelector(
    (state) => state.lang.value.exportRegistry
  );

  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const importRegistryStore = useSelector(
    (state) => state.importRegistry.value
  );

  const filters = [
    {
      name: globalLang.name[lang],
      component: <NameFilter />,
    },
    {
      name: globalLang.date[lang],
      component: <DateFilter />,
    },
  ];

  const columns = [
    {
      field: "users",
      headerName: globalLang.name[lang],
    },
    {
      field: "created_at",
      headerName: globalLang.createdAt[lang],
      customContent: (params) => {
        return format(params?.created_at);
      },
    },
    {
      field: "post_file",
      headerName: globalLang.file[lang],
      customContent: (params) => {
        return (
          <IconButton
            onClick={() => {
              window.open(params.post_file, "_blank");
            }}
          >
            <DownloadIcon />
          </IconButton>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      ...old,
      filters: filters.map(({ query }) => query),
    }));
  };

  const [importRegistryGetRequest, importRegistryGetResponse] = useRequest({
    path: FILES_HISTORY,
    method: "get",
  });

  useEffect(() => {
    const urlParams = new URLSearchParams();

    urlParams.append("type", "1");

    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );
    importRegistryGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "importRegistry/set", payload: res.data });
      },
    });
  }, [requestParams]);

  //====start==== delete registry logic ============
  const [deleteRegistryRequest, deleteRegistryResponse] = useRequest({
    path: FILES_HISTORY,
    method: "delete",
    successMessage: "تم حذف السجل بنجاح",
  });

  const deleteRegistry = (e, row) => {
    deleteRegistryRequest({
      id: row.id,
      params: {
        type: 1,
      },
      onSuccess: () => {
        dispatch({
          type: "importRegistry/deleteItem",
          payload: { id: row.id },
        });
      },
    });
  };

  const [handleDeleteRegistry, deleteRegistryConfirmMessage] =
    useConfirmMessage({
      onConfirm: deleteRegistry,
      text: "هل انت متأكد من انك تريد حذف هذه السجل؟",
    });

  const isPermitted = useIsPermitted();
  //====end==== delete registry logic ============

  return (
    <Wrapper>
      <Breadcrumbs
        path={[
          globalLang.customers[lang],
          exportRegistryLang.exportRegistry[lang],
        ]}
      />
      <DataGrid
        columns={columns}
        rows={importRegistryStore.results}
        total={importRegistryStore.count}
        isPending={importRegistryGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        onDelete={isPermitted(handleDeleteRegistry, [
          "delete_aqarimportexportfiels",
        ])}
        filters={filters}
      />
      {deleteRegistryResponse.successAlert}
      {deleteRegistryResponse.failAlert}

      {deleteRegistryConfirmMessage}
    </Wrapper>
  );
};

export default ImportRegistry;

const NameFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const handleChange = (e) => {
    onChange({
      query: ["name", e.target.value],
      renderedValue: e.target.value,
      value: e.target.value,
    });
  };

  return (
    <InputField
      placeholder={globalLang.name[lang]}
      value={value}
      onChange={handleChange}
    />
  );
};

const DateFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [startPoint, setStartPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };

  useEffect(() => {
    if (!startPoint && !endPoint) return;
    onChange({
      query: [
        "created_at",
        `${formatDate(String(startPoint))}-${formatDate(String(endPoint))}`,
      ],
      renderedValue: `${globalLang.from[lang]} ${formatDate(
        String(startPoint)
      )} - ${globalLang.to[lang]} ${formatDate(String(endPoint))}`,
      value: {
        start: startPoint,
        end: endPoint,
      },
    });
  }, [startPoint, endPoint]);

  const handleChangeStartPoint = (e) => {
    setStartPoint(e.target.value);
  };

  const handleChangeEndPoint = (e) => {
    setEndPoint(e.target.value);
  };

  return (
    <Stack spacing={2}>
      <TextField
        variant="standard"
        type="date"
        label={globalLang.from[lang]}
        value={value.start}
        onChange={handleChangeStartPoint}
      />
      <TextField
        variant="standard"
        type="date"
        label={globalLang.to[lang]}
        value={value.end}
        onChange={handleChangeEndPoint}
      />
    </Stack>
  );
};
