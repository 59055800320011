import { createSlice } from "@reduxjs/toolkit";
export const LangSlice = createSlice({
  name: "lang",
  initialState: {
    value: {
      lang: localStorage.getItem("lang")
        ? JSON.parse(localStorage.getItem("lang"))
        : "ar",
      home: { ar: "الرئيسية", en: "Home" },

      control: {
        changePassword: { ar: "تغير الرقم السري", en: "Change Password" },
        changeLanguage: { ar: "تغير اللغه", en: "Change Language" },
      },

      // Employees
      addEmployees: {
        addEmployee: { ar: "إضافة موظف", en: "Add Employee" },
        job: { ar: "الوظيفة", en: "job" },
        followed: { ar: "التابع له", en: "followed by" },
      },
      addJob: {
        addEmployee: { ar: "إضافة وظيفة موظف", en: "Add Employee Job" },
        followed: { ar: "التابع له", en: "followed by" },
      },
      viewJobs: {
        viewJobs: { ar: "عرض وظائف الموظفين", en: "View employee jobs" },
        followed: { ar: "التابع له", en: "followed by" },
        jobName: { ar: "اسم الوظيفة", en: "Job Name" },
        createdAt: { ar: "تاريخ الإنشاء", en: "Created At" },
      },
      viewEmployees: {
        viewEmployees: { ar: "عرض الموظفين", en: "View Employees" },
        avatar: { ar: "الصورة الشخصية", en: "Avatar" },
        createdAt: { ar: "تاريخ الإنشاء", en: "Created At" },
        employeesNumbers: { ar: "عدد العملاء", en: "Number of clients" },
        newEmployeesNumbers: {
          ar: "عدد العملاء الجدد",
          en: "Number of new clients",
        },
      },

      //////////////

      // client

      overview: {
        statistics: { ar: "الإحصائيات", en: "statistics" },
        allClients: { ar: "جميع العملاء", en: "All clients" },
        newCustomers: { ar: "العملاء الجدد", en: "New Customers" },
        allEmployees: { ar: "جميع الموظفين", en: "All Employees" },
        newEmployees: { ar: "الموظفين الجدد", en: "New Employees" },
        customerCase: { ar: "حالة العميل", en: "Customer Case" },
        bestEmployee: { ar: "افضل موظف", en: "Best Employee" },
        advertisingChannel: {
          ar: "القناة الاعلانية",
          en: "AdvertisingChannel",
        },
      },

      addClients: {
        addClients: { ar: "إضافة عميل", en: "Add Clients" },
      },

      viewClients: {
        allClients: { ar: "جميع العملاء", en: "All Clients" },
      },
      deleteCustomer: {
        deleteCustomer: { ar: "العملاء المحذوفين", en: "Deleted Customers" },
      },
      addStatus: {
        addStatus: { ar: "إضافة حالة عميل", en: "Add Status" },
      },
      viewStatus: {
        viewStatus: { ar: "عرض حالات العملاء", en: "View customer cases" },
      },
      importClients: {
        importClients: { ar: "إستيراد عملاء", en: "Import Clients" },
      },
      exportClients: {
        exportClients: { ar: "تصدير عملاء", en: "Export Clients" },
      },
      exportRegistry: {
        exportRegistry: { ar: "سجل التصدير", en: "Export Registry" },
      },
      importLog: {
        importLog: { ar: "سجل الإستيراد", en: "Import Log" },
      },

      ////////////////////

      // Project
      addProjects: {
        addProjects: { ar: "إضافة مشروع", en: "Add Project" },
        addPhoto: { ar: "اضف صورة المشروع هنا", en: "Add Photo Here" },
        projectName: { ar: "إسم المشروع", en: "Project Name" },
        projectAddress: { ar: "عنوان المشروع", en: "Project Address" },
      },

      viewProjects: {
        viewProjects: { ar: "عرض المشاريع", en: "View Projects" },
        projectName: { ar: "إسم المشروع", en: "Project Name" },
      },

      ////////////////////

      // Channels
      addChannel: {
        addChannel: { ar: "إضافة قناة", en: "Add Channel" },
        addChannelPhoto: {
          ar: "اضف صورة القناة هنا",
          en: "Add Channel Photo Here",
        },
        channelName: { ar: "إسم القناة", en: "Channel Name" },
      },

      viewChannels: {
        viewChannels: { ar: "عرض المشاريع", en: "View Projects" },
        projectName: { ar: "إسم المشروع", en: "Project Name" },
      },

      ////////////////////

      // Units
      addUnits: {
        addUnits: { ar: "إضافة وحدة", en: "Add Units" },
      },
      viewUnits: {
        viewUnits: { ar: "عرض الوحدات", en: "View Units" },
      },

      ////////////////////

      form: {
        title: { ar: "مرحباً بك", en: "Hello" },
        subtitle: {
          ar: "قم بملئ الحقول للحصول على النتائج المطلوبة",
          en: "Fill in the fields to get the required results",
        },
      },
      global: {
        logout: { ar: "تسجيل الخروج", en: "Logout" },
        control: { ar: "التحكم", en: "Control" },

        // user data
        name: { ar: "الإسم", en: "Name" },
        username: { ar: "إسم المستخدم", en: "Username" },
        customer: { ar: "العميل", en: "Customer" },
        customerName: { ar: "اسم العميل", en: "Customer Name" },
        customerPhone: { ar: "هاتف العميل", en: "Customer Phone" },
        employeeName: { ar: "اسم الموظف", en: "Employee Name" },
        employeePhone: { ar: "رقم الموظف", en: "Employee Phone" },
        phone: { ar: "الهاتف", en: "Phone" },
        email: { ar: "البريد الإلكتروني", en: "E-mail" },
        password: { ar: "الرقم السري", en: "Password" },
        newPassword: { ar: "الرقم السري الجديد", en: "New Password" },
        oldPassword: { ar: "الرقم السري القديم", en: "Old Password" },
        confirmPassword: { ar: "تأكيد الرقم السري", en: "Confirm Password" },
        whatsApp: { ar: "الواتساب", en: "whats app" },
        contactWay: { ar: "طريقة التواصل", en: "Contact Way" },
        password: { ar: "الرقم السري الجديد", en: "New Password" },
        job: { ar: "الوظيفة", en: "Job" },
        employee: { ar: "الموظف", en: "Employee" },
        TransferEmployee: {
          ar: "تحويل إلى موظف",
          en: "Transfer To An Employee",
        },
        salesOfficer: { ar: "مسؤول المبيعات", en: "Sales Officer" },
        newCustomers: { ar: "العملاء الجدد", en: "New Customers" },
        editor: { ar: "المحرر", en: "Editor" },
        createdBy: { ar: "تم الإنشاء بواسطة", en: "Created By" },

        //////////////////////

        // Actions
        actions: { ar: "إجرائات", en: "Actions" },
        save: { ar: "حفظ", en: "Save" },
        delete: { ar: "حذف", en: "Delete" },
        deleteTo: { ar: "نقل إلى سلة المهملات", en: "Move to trash" },
        cancel: { ar: "الغاء", en: "Cancel" },
        submit: { ar: "تنفيذ", en: "Submit" },
        add: { ar: "إضافة", en: "Add" },
        addComment: { ar: "اكتب تعليق", en: "Add Comment" },
        editPost: { ar: "تعديل المنشور", en: "Edit Post" },
        editUnitData: { ar: "تعديل بيانات الوحدة", en: "Edit Unit Data" },
        search: { ar: "بحث", en: "Search" },
        viewItems: { ar: "عرض العناصر", en: "View Items" },
        editJob: { ar: "تعديل الوظيفة", en: "Edit Job" },
        showAll: { ar: "عرض الجميع", en: "Show All" },
        filter: { ar: "تصفية", en: "Filter" },
        addFilter: { ar: "إضافة تصفية", en: "Add Filter" },
        status: { ar: "الحالة", en: "Status" },
        deleteRecords: { ar: "حذف السجلات", en: "Delete Records" },
        downloadFile: { ar: "تنزيل ملف", en: "Download File" },
        uploadFile: { ar: "رفع ملف", en: "Upload File" },
        downloadTemplate: { ar: "تنزيل نموذج", en: "Download Template" },
        created_by:{ar:'بواسطة',en:"Created By"},
        downloadCustomerData: {
          ar: "قم بتحميل ملف العملاء",
          en: "Download Customer Data",
        },
        uploadCustomerData: {
          ar: "قم برفع ملف العملاء",
          en: "Upload Customer Data",
        },
        pleaseConfirm: { ar: "الرجاء التأكيد", en: "please, confirm" },
        areUSure: {
          ar: "هل انت متأكد من انك تريد اتمام العملية؟",
          en: "Are you sure you want to delete this client?",
        },
        editCusomerData: {
          ar: "تعديل بيانات العميل",
          en: "Edit Customer Data",
        },
        cusomerDetails: {
          ar: "تفاصيل العميل",
          en: "Customer Details",
        },
        customerRecord: {
          ar: "سجل العميل",
          en: "Customer Record",
        },

        ///////////////////////

        // Selected
        deleteSelected: { ar: "حذف المحدد", en: "Delete Selected" },
        retrievalSelected: { ar: "إسترجاع المحدد", en: "retrieval Selected" },
        exportSelected: { ar: "تصدير المحدد", en: "Export Selected" },
        transferSelected: { ar: "تحويل المحدد", en: "Transfer Selected" },
        changeSelected: {
          ar: "تغيير مشاريع المحدد",
          en: "Change Selected Projects",
        },
        ///////////////////////

        // Single word
        no: { ar: "لا", en: "No" },
        yes: { ar: "نعم", en: "Yes" },
        ok: { ar: "حسناً", en: "OK" },
        admin: { ar: "أدمن", en: "Admin" },
        like: { ar: "أعجبني", en: "Like" },
        comment: { ar: "التعليق", en: "Comment" },
        comments: { ar: "تعليقات", en: "Comments" },
        theDescription: { ar: "الوصف", en: "The Description" },
        file: { ar: "الملف", en: "File" },
        picture: { ar: "الصورة", en: "Picture" },
        details: { ar: "تفاصيل", en: "Details" },
        notes: { ar: "ملاحظات", en: "Notes" },

        ///////////////////

        // Location
        country: { ar: "البلد", en: "Country" },
        governorate: { ar: "المحافظة", en: "Governorate" },
        city: { ar: "المدينة", en: "city" },
        region: { ar: "المنطقة", en: "Region" },
        neighboring: { ar: "المجاورة", en: "Neighboring" },
        buildingNumber: { ar: "رقم العمارة", en: "Building Number" },
        unitType: { ar: "نوع الوحدة", en: "Unit Type" },
        saleType: { ar: "نوع البيع", en: "Sale Type" },
        floor: { ar: "الدور", en: "Floor" },
        area: { ar: "المساحة", en: "Area" },
        roomsNumber: { ar: "عدد الغرف", en: "Rooms Number" },
        bathroomsNumber: { ar: "عدد الحمامات", en: "Bathrooms Number" },
        finishingType: { ar: "نوع التشطيب", en: "Finishing Type" },
        price: { ar: "السعر", en: "Price" },
        address: { ar: "العنوان", en: "Address" },

        ///////////////////

        // scale unites
        meter: { ar: "متر", en: "Meter" },

        ///////////////////

        latestPosts: { ar: "احدث المنشورات", en: "Latest Posts" },
        yourMind: { ar: "مالذي يدور في بالك،", en: "what's on your mind," },
        followed: { ar: "التابع له", en: "followed by" },
        allPermission: { ar: "جميع الصلاحيات", en: "All Permission" },
        notFound: { ar: "لا يوجد", en: "Not Found" },
        unknown: { ar: "غير معروف", en: "Unknown" },
        unknownLink: { ar: "الرابط غير معروف", en: "Unknown Link" },
        code: { ar: "الكود", en: "Code" },
        numberOfClients: { ar: "عدد العملاء", en: "Number of clients" },
        project: { ar: "المشروع", en: "Project" },
        projects: { ar: "المشروعات", en: "Projects" },
        budget: { ar: "الميزانية", en: "Budget" },
        totalNumber: { ar: "العدد الكلي", en: " total number" },
        sameLevel: { ar: "نفس المرحلة", en: "Same Level" },
        editEmployeeData: {
          ar: "تعديل بيانات الموظفين",
          en: "Edit Employee Data",
        },
        adChannel: {
          ar: "القناة الإعلانية",
          en: "Ad Channel",
        },
        channels: {
          ar: "القنوات",
          en: "Channels",
        },
        mediator: {
          ar: "الوسيط",
          en: "Mediator",
        },
        statusName: {
          ar: "اسم الحالة",
          en: "Status name",
        },

        // Budget
        budget: { ar: "الميزانية", en: "Budget" },
        equal: { ar: "يساوي", en: "Equal" },
        bigger: { ar: "اكبر من", en: "Bigger" },
        smaller: { ar: "اصغر من", en: "Smaller" },

        //////////////////

        // Dates
        day: { ar: "اليوم", en: "Day" },
        week: { ar: "الإسبوع", en: "Week" },
        month: { ar: "الشهر", en: "Month" },
        year: { ar: "السنة", en: "Year" },

        date: { ar: "تاريخ", en: "Date" },
        createdAt: { ar: "تاريخ الإنشاء", en: "Created At" },
        previewDate: { ar: "تاريخ المعاينة", en: "Preview Date" },
        dateOfProcedure: { ar: "تاريخ الإجراء", en: "Date Of Procedure" },
        from: { ar: "من", en: "From" },
        periodFrom: { ar: "الفترة من", en: "Period From" },
        to: { ar: "الي", en: "To" },
        periodTo: { ar: "الفترة إلى", en: "Period To" },
        ///////////////

        // Sidebar
        Employees: { ar: "الموظفين", en: "Employees" },
        customers: { ar: "العملاء", en: "Customers" },
        projects: { ar: "المشاريع", en: "Projects" },
        channels: { ar: "القنوات", en: "Channels" },
        units: { ar: "الوحدات", en: "Units" },
        additional_phone:{ar:"هاتف ثاني",en:'Additional Phone'},
        files_number:{ar:"عدد الملفات",en:"Number"}
      },
    },
  },
  reducers: {
    toggleLang: (state, action) => {
      if (
        Boolean(action.payload) &&
        action.payload != "ar" &&
        action.payload != "en"
      ) {
        throw new Error(
          "The only possible languages to toggle between are either ar or en"
        );
      }

      if (action.payload) {
        state.value.lang = action.payload;
        localStorage.setItem("lang", JSON.stringify(action.payload));
      } else {
        switch (state.value.lang) {
          case "en":
            state.value.lang = "ar";
            break;
          case "ar":
            state.value.lang = "en";
            break;
          default:
            state.value.lang = "en";
        }
      }
    },
  },
});

export default LangSlice.reducer;
