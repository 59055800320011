import React, { useEffect, useState } from "react";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import CollectionsIcon from "@mui/icons-material/Collections";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import DataGrid from "../../components/DataGrid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Zoom from "react-reveal/Zoom";
import Jump from "react-reveal/Jump";
import {
  Avatar,
  Badge,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";

import useRequest from "../../hooks/useRequest";
import { COUNTRY_FILTER, STATES, STATE_CITIES, UNITS } from "../../data/APIs";
import { useDispatch, useSelector } from "react-redux";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import Form, { InputField, SelectField } from "../../features/form";
import useControls from "../../hooks/useControls";
import filter from "../../utils/ClearNull";
import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogContent,
  DialogForm,
  DialogHeading,
  DialogInfoWindow,
  DialogInputField,
  DialogPhoneField,
  DialogSelectField,
} from "../../features/dialog";
import useAfterEffect from "../../hooks/useAfterEffect";
import DialogNumberField from "../../features/dialog/components/DialogNumberField";
import compare from "../../utils/Compare";
import AddIcon from "@mui/icons-material/Add";
import { Box, Stack } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { useRef } from "react";
import usePropState from "../../hooks/usePropState";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

import {
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  RedditShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  RedditIcon,
  TumblrIcon,
  LivejournalIcon,
  MailruIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  EmailIcon,
} from "react-share";
import format from "../../utils/ISOToReadable";
import { NavLink, useNavigate } from "react-router-dom";
import { TypeSpecimenTwoTone } from "@mui/icons-material";

const ViewUnits = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewUnitsLang = useSelector((state) => state.lang.value.viewUnits);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const unitsStore = useSelector((state) => state.units.value);
  const floors = [
    {
      name: "البدروم",
      value: "g",
    },
    {
      name: "الدور الأرضي",
      value: "0",
    },
    {
      name: "1",
      value: "1",
    },
    {
      name: "2",
      value: "1",
    },

    {
      name: "3",
      value: "3",
    },
    {
      name: "4",
      value: "4",
    },
    {
      name: "5",
      value: "5",
    },
    {
      name: "6",
      value: "6",
    },
    {
      name: "7",
      value: "7",
    },
    {
      name: "8",
      value: "8",
    },

    {
      name: "9",
      value: "9",
    },
    {
      name: "10",
      value: "10",
    },
    {
      name: "11",
      value: "11",
    },
    {
      name: "12",
      value: "12",
    },
    {
      name: "13",
      value: "13",
    },
    {
      name: "14",
      value: "14",
    },
    {
      name: "15",
      value: "15",
    },
    {
      name: "16",
      value: "16",
    },
    {
      name: "17",
      value: "17",
    },
    {
      name: "18",
      value: "18",
    },
    {
      name: "19",
      value: "19",
    },
    {
      name: "20",
      value: "20",
    },
  ];

  const columns = [
    {
      field: "picture",
      headerName: globalLang.picture[lang],
      customContent: (params) => {
        return (
          <Avatar
            src={params.images[0]?.image}
            variant="rounded"
            sx={{ width: 90, height: 90 }}
          >
            U
          </Avatar>
        );
      },
    },
    {
      field: "area",
      headerName: globalLang.area[lang],
      customContent: (params) => {
        return params.area_size;
      },
    },
    {
      field: "rooms",
      headerName: globalLang.roomsNumber[lang],
      customContent: (params) => {
        return params.room_number;
      },
    },
    {
      field: "genre",
      headerName: globalLang.finishingType[lang],
      customContent: (params) => {
        return params.complete_type;
      },
    },
    {
      field: "district",
      headerName: globalLang.region[lang],
      customContent: (params) => {
        return params.area;
      },
    },
    {
      field: "price",
      headerName: globalLang.price[lang],
      customContent: (params) => {
        return params.price;
      },
    },
    {
      field: "floor",
      headerName: globalLang.floor[lang],
      customContent: (params) => {
        return params.floor_number;
      },
    },
    {
      field: "address",
      headerName: globalLang.address[lang],
      customContent: (params) => {
        return params.address;
      },
    },
  ];

  const dispatch = useDispatch();

  const [unitsGetRequest, unitsGetResponse] = useRequest({
    path: UNITS,
    method: "get",
  });

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    const urlParams = new URLSearchParams();

    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    unitsGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "units/set", payload: res.data });
      },
    });
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const [unitDeleteRequest, unitDeleteResponse] = useRequest({
    path: UNITS,
    method: "delete",
    successMessage: "تم حذف الوحدة بنجاح",
  });

  const deleteUnit = (e, row) => {
    unitDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "units/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteUnit, deleteUnitConfirmDialog] = useConfirmMessage({
    onConfirm: deleteUnit,
    text: "هل انت متأكد من انك تريد حذف هذه الوحدة",
  });

  const [{ controls }, { setControl, resetControls }] = useControls([
    {
      control: "country",
      value: "",
      isRequired: false,
    },
    {
      control: "governorate",
      value: "",
      isRequired: false,
    },
    {
      control: "city",
      value: "",
      isRequired: false,
    },
    {
      control: "district",
      value: "",
      isRequired: false,
    },
    {
      control: "near",
      value: "",
      isRequired: false,
    },
    {
      control: "number",
      value: "",
      isRequired: false,
    },
    {
      control: "type",
      value: "",
      isRequired: false,
    },
    {
      control: "sale",
      value: "",
      isRequired: false,
    },
    {
      control: "floor",
      value: "",
      isRequired: false,
    },
    {
      control: "area",
      value: "",
      isRequired: false,
    },
    {
      control: "rooms",
      value: "",
      isRequired: false,
    },
    {
      control: "bathrooms",
      value: "",
      isRequired: false,
    },
    {
      control: "genre",
      value: "",
      isRequired: false,
    },
    {
      control: "price",
      value: "",
      isRequired: false,
    },
    {
      control: "client",
      value: "",
      isRequired: false,
    },
  ]);

  const handleFilter = () => {
    const requestBody = filter({
      obj: {
        country: controls.country,
        state: controls.governorate,
        city: controls.city,
        area: controls.district,
        part: controls.near,
        flat_number: controls.number,
        unit_type: controls.type,
        sales_type: controls.sale,
        floor_number: controls.floor,
        area_size: controls.area,
        room_number: controls.rooms,
        bath_count: controls.bathrooms,
        complete_type: controls.genre,
        price: controls.price,
        client: controls.client,
      },
    });

    setRequestParams((old) => ({
      ...old,
      filters: Object.entries(requestBody),
    }));
  };

  const handleResetFilter = () => {
    resetControls();

    setRequestParams((old) => ({
      ...old,
      filters: [],
    }));
  };

  const [editData, setEditData] = useState(null);

  const [infoData, setInfoData] = useState(null);

  const isPermitted = useIsPermitted();

  //===Start===== Get Countries logic =========
  const [countriesData, setCountriesData] = useState([]);

  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  const getCountries = () => {
    countriesGetRequest({
      onSuccess: (res) => {
        setCountriesData(res.data.data);
      },
    });
  };

  const [governoratesData, setGovernoratesData] = useState([]);

  const [statesGetRequest, statesGetResponse] = useRequest({
    path: STATES,
    method: "post",
  });

  const getGovernorates = () => {
    statesGetRequest({
      body: {
        country: controls.country,
      },
      onSuccess: (res) => {
        setGovernoratesData(res.data.data);
      },
    });
  };

  //===End===== Get Countries logic =========

  return (
    <Wrapper>
      <Breadcrumbs
        path={[globalLang.units[lang], viewUnitsLang.viewUnits[lang]]}
      />
      <Form
        childrenProps={{
          saveBtn: {
            children: globalLang.filter[lang],
            onClick: handleFilter,
          },
          closeBtn: {
            onClick: handleResetFilter,
          },
        }}
      >
        <SelectField
          label={globalLang.country[lang]}
          placeholder={globalLang.country[lang]}
          onOpen={getCountries}
          isPending={countriesGetResponse.isPending}
          value={controls.country}
          onChange={(e) => {
            setControl("country", e.target.value);
            setControl("governorate", "");
            setControl("city", "");
          }}
        >
          {countriesData.map((country, index) => (
            <MenuItem value={country} key={`country ${index}`}>
              {country}
            </MenuItem>
          ))}
        </SelectField>
        <SelectField
          label={globalLang.governorate[lang]}
          placeholder={globalLang.governorate[lang]}
          disabled={!Boolean(controls.country)}
          onOpen={getGovernorates}
          isPending={statesGetResponse.isPending}
          value={controls.governorate}
          onChange={(e) => {
            setControl("governorate", e.target.value);
            setControl("city", "");
          }}
        >
          {governoratesData.map((governorate, index) => (
            <MenuItem value={governorate} key={`state ${index}`}>
              {governorate}
            </MenuItem>
          ))}
        </SelectField>
        <InputField
          label={globalLang.city[lang]}
          placeholder={globalLang.city[lang]}
          value={controls.city}
          onChange={(e) => setControl("city", e.target.value)}
        />
        <InputField
          label={globalLang.region[lang]}
          placeholder={globalLang.region[lang]}
          value={controls.district}
          onChange={(e) => setControl("district", e.target.value)}
        />
        <InputField
          label={globalLang.neighboring[lang]}
          placeholder={globalLang.neighboring[lang]}
          value={controls.near}
          onChange={(e) => setControl("near", e.target.value)}
        />
        <InputField
          label={globalLang.buildingNumber[lang]}
          placeholder={globalLang.buildingNumber[lang]}
          value={controls.number}
          onChange={(e) => setControl("number", e.target.value)}
        />
        <InputField
          label={globalLang.unitType[lang]}
          placeholder={globalLang.unitType[lang]}
          value={controls.type}
          onChange={(e) => setControl("type", e.target.value)}
        />
        <InputField
          label={globalLang.saleType[lang]}
          placeholder={globalLang.saleType[lang]}
          value={controls.sale}
          onChange={(e) => setControl("sale", e.target.value)}
        />
        {/* <InputField
          label={globalLang.floor[lang]}
          placeholder={globalLang.floor[lang]}
          value={controls.floor}
          onChange={(e) => setControl("floor", e.target.value)}
        /> */}
        <SelectField
          label={globalLang.floor[lang]}
          placeholder={globalLang.floor[lang]}
          value={controls.floor}
          renderValue={(selected) =>
            floors.find((f) => f.value === selected).name
          }
          onChange={(e) => setControl("floor", e.target.value)}
        >
          {floors.map((floor, index) => (
            <MenuItem value={floor.value} key={` ${index}`}>
              {floor.name}
            </MenuItem>
          ))}
        </SelectField>
        <InputField
          label={globalLang.area[lang]}
          placeholder={globalLang.area[lang]}
          value={controls.area}
          onChange={(e) => setControl("area", e.target.value)}
        />
        <InputField
          label={globalLang.roomsNumber[lang]}
          placeholder={globalLang.roomsNumber[lang]}
          value={controls.rooms}
          onChange={(e) => setControl("rooms", e.target.value)}
        />
        <InputField
          label={globalLang.bathroomsNumber[lang]}
          placeholder={globalLang.bathroomsNumber[lang]}
          value={controls.bathrooms}
          onChange={(e) => setControl("bathrooms", e.target.value)}
        />
        <InputField
          label={globalLang.finishingType[lang]}
          placeholder={globalLang.finishingType[lang]}
          value={controls.genre}
          onChange={(e) => setControl("genre", e.target.value)}
        />
        <InputField
          label={globalLang.price[lang]}
          placeholder={globalLang.price[lang]}
          value={controls.price}
          onChange={(e) => setControl("price", e.target.value)}
        />
        <InputField
          label={globalLang.customerName[lang]}
          placeholder={globalLang.customerName[lang]}
          value={controls.client}
          onChange={(e) => setControl("client", e.target.value)}
        />
      </Form>
      <DataGrid
        columns={columns}
        rows={unitsStore.results}
        total={unitsStore.count}
        isPending={unitsGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onDelete={isPermitted(handleDeleteUnit, ["delete_aqarunit"])}
        onEdit={isPermitted((e, row) => setEditData(row), ["change_aqarunit"])}
        onView={(e, row) => setInfoData(row)}
      />

      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      <InfoDialog
        open={Boolean(infoData)}
        onClose={() => setInfoData(null)}
        data={infoData}
      />
      {deleteUnitConfirmDialog}
      {unitDeleteResponse.successAlert}
      {unitDeleteResponse.failAlert}
    </Wrapper>
  );
};

export default ViewUnits;

const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "country",
        value: data?.country,
        isRequired: false,
      },
      {
        control: "governorate",
        value: data?.state,
        isRequired: false,
      },
      {
        control: "city",
        value: data?.city,
        isRequired: false,
      },
      {
        control: "district",
        value: data?.area,
        isRequired: false,
      },
      {
        control: "near",
        value: data?.part,
        isRequired: false,
      },
      {
        control: "address",
        value: data?.address,
        isRequired: false,
      },
      {
        control: "number",
        value: data?.flat_number,
        isRequired: false,
      },
      {
        control: "type",
        value: data?.unit_type,
        isRequired: false,
      },
      {
        control: "floor",
        value: data?.floor_number,
        isRequired: false,
      },
      {
        control: "area",
        value: data?.area_size,
        isRequired: false,
      },
      {
        control: "rooms",
        value: data?.room_number,
        isRequired: false,
      },
      {
        control: "bathrooms",
        value: data?.bath_count,
        isRequired: false,
      },
      {
        control: "genre",
        value: data?.complete_type,
        isRequired: false,
      },
      {
        control: "price",
        value: data?.price,
        isRequired: false,
      },
      {
        control: "client",
        value: data?.client,
        isRequired: false,
      },
      {
        control: "countryCode",
        value: data?.country_code_phone_client,
        isRequired: false,
      },
      {
        control: "phone",
        value: data?.phone_client,
        isRequired: false,
      },
      {
        control: "notes",
        value: data?.comment,
        isRequired: false,
      },
    ],
    [data]
  );

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  const [unitPatchRequest, unitPatchResponse] = useRequest({
    path: UNITS,
    method: "patch",
    successMessage: "تم تعديل الوحدة بنجاح",
  });

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const isThereChange = !compare(
      [
        [controls.country, data.country],
        [controls.governorate, data.state],
        [controls.city, data.city],
        [controls.district, data.area],
        [controls.near, data.part],
        [controls.address, data.address],
        [controls.number, data.flat_number],
        [controls.type, data.unit_type],
        [controls.floor, data.floor_number],
        [controls.area, data.area_size],
        [controls.rooms, data.room_number],
        [controls.bathrooms, data.bath_count],
        [controls.genre, data.complete_type],
        [controls.price, data.price],
        [controls.client, data.client],
        [controls.phone, data.phone_client],
        [controls.countryCode, data.country_code_phone_client],
        [controls.notes, data.comment],
      ],
      true
    );

    if (isThereChange) {
      const requestBody = filter({
        obj: {
          country: controls.country,
          state: controls.governorate,
          city: controls.city,
          area: controls.district,
          part: controls.near,
          address: controls.address,
          flat_number: controls.number,

          unit_type: controls.type,
          sales_type: controls.sale,
          floor_number: controls.floor,
          area_size: controls.area,
          room_number: controls.rooms,
          bath_count: controls.bathrooms,
          complete_type: controls.genre,
          price: controls.price,
          client: controls.client,
          phone_client: controls.countryCode + controls.phone,
          comment: controls.notes,
        },
      });
      unitPatchRequest({
        id: data.id,
        body: requestBody,
        onSuccess: (res) => {
          dispatch({
            type: "units/putItem",
            payload: { id: res.data.id, item: res.data },
          });
          onClose();
        },
      });
    }
  };

  //===Start===== Get Countries logic =========
  const [countriesData, setCountriesData] = useState([]);

  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  const getCountries = () => {
    if (countriesData.length) return;
    countriesGetRequest({
      onSuccess: (res) => {
        setCountriesData(res.data.data);
      },
    });
  };

  const [governoratesData, setGovernoratesData] = useState([]);

  const [statesGetRequest, statesGetResponse] = useRequest({
    path: STATES,
    method: "post",
  });

  const getGovernorates = () => {
    if (governoratesData.length) return;
    statesGetRequest({
      body: {
        country: controls.country,
      },
      onSuccess: (res) => {
        setGovernoratesData(res.data.data);
      },
    });
  };

  //===End===== Get Countries logic =========

  return (
    <Dialog open={open} onClose={onClose} paperProps={{ height: "100%" }}>
      <DialogHeading>{globalLang.editUnitData[lang]}</DialogHeading>
      <DialogForm>
        <DialogSelectField
          label={globalLang.country[lang]}
          placeholder={globalLang.country[lang]}
          onOpen={getCountries}
          isPending={countriesGetResponse.isPending}
          value={controls.country}
          onChange={(e) => {
            setControl("country", e.target.value);
            setControl("governorate", "");
            setControl("city", "");
          }}
        >
          {countriesData.map((country, index) => (
            <MenuItem value={country} key={`country ${index}`}>
              {country}
            </MenuItem>
          ))}
        </DialogSelectField>
        <DialogSelectField
          label={globalLang.governorate[lang]}
          placeholder={globalLang.governorate[lang]}
          disabled={!Boolean(controls.country)}
          onOpen={getGovernorates}
          isPending={statesGetResponse.isPending}
          value={controls.governorate}
          onChange={(e) => {
            setControl("governorate", e.target.value);
            setControl("city", "");
          }}
        >
          {governoratesData.map((governorate, index) => (
            <MenuItem value={governorate} key={`state ${index}`}>
              {governorate}
            </MenuItem>
          ))}
        </DialogSelectField>
        <DialogInputField
          label={globalLang.city[lang]}
          placeholder={globalLang.city[lang]}
          value={controls.city}
          onChange={(e) => setControl("city", e.target.value)}
        />
        <DialogInputField
          placeholder={globalLang.region[lang]}
          label={globalLang.region[lang]}
          value={controls.district}
          onChange={(e) => setControl("district", e.target.value)}
        />
        <DialogInputField
          placeholder={globalLang.neighboring[lang]}
          label={globalLang.neighboring[lang]}
          value={controls.near}
          onChange={(e) => setControl("near", e.target.value)}
        />
        <DialogInputField
          placeholder={globalLang.address[lang]}
          label={globalLang.address[lang]}
          value={controls.address}
          onChange={(e) => setControl("address", e.target.value)}
        />
        <DialogNumberField
          placeholder={globalLang.buildingNumber[lang]}
          label={globalLang.buildingNumber[lang]}
          value={controls.number}
          onChange={(e) => setControl("number", e.target.floatValue)}
        />
        <DialogInputField
          placeholder={globalLang.unitType[lang]}
          label={globalLang.unitType[lang]}
          value={controls.type}
          onChange={(e) => setControl("type", e.target.value)}
        />
        <DialogNumberField
          placeholder={globalLang.floor[lang]}
          label={globalLang.floor[lang]}
          value={controls.floor}
          onChange={(e) => setControl("floor", e.target.floatValue)}
        />
        <DialogNumberField
          placeholder={globalLang.area[lang]}
          label={globalLang.area[lang]}
          prefix="متر "
          value={controls.area}
          onChange={(e) =>
            setControl("area", e.target.floatValue.replace(/متر/gi, "").trim())
          }
        />
        <DialogNumberField
          placeholder={globalLang.roomsNumber[lang]}
          label={globalLang.roomsNumber[lang]}
          value={controls.rooms}
          onChange={(e) => setControl("rooms", e.target.floatValue)}
        />
        <DialogNumberField
          placeholder={globalLang.bathroomsNumber[lang]}
          label={globalLang.bathroomsNumber[lang]}
          value={controls.bathrooms}
          onChange={(e) => setControl("bathrooms", e.target.floatValue)}
        />
        <DialogInputField
          placeholder={globalLang.finishingType[lang]}
          label={globalLang.finishingType[lang]}
          value={controls.genre}
          onChange={(e) => setControl("genre", e.target.value)}
        />
        <DialogNumberField
          placeholder={globalLang.price[lang]}
          label={globalLang.price[lang]}
          value={controls.price}
          thousandSeparator
          onChange={(e) => setControl("price", e.target.floatValue)}
        />
        <DialogInputField
          placeholder={globalLang.customerName[lang]}
          label={globalLang.customerName[lang]}
          value={controls.client}
          onChange={(e) => setControl("client", e.target.value)}
        />
        <DialogPhoneField
          placeholder={globalLang.customerPhone[lang]}
          label={globalLang.customerPhone[lang]}
          selectProps={{
            value: controls.countryCode,
            onChange: (e) => setControl("countryCode", e.target.value),
          }}
          value={controls.phone}
          onChange={(e) => setControl("phone", e.target.value)}
        />
        <DialogInputField
          placeholder={globalLang.notes[lang]}
          label={globalLang.notes[lang]}
          value={controls.notes}
          onChange={(e) => setControl("notes", e.target.value)}
        />
      </DialogForm>
      <DialogButtonsGroup>
        <DialogButton
          onClick={handleSubmit}
          disabled={unitPatchResponse.isPending}
        >
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
      {unitPatchResponse.failAlert}
    </Dialog>
  );
};

const InfoDialog = ({ open, onClose, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const info = [
    {
      name: globalLang.employeeName[lang],
      value: data?.agent,
    },
    {
      name: globalLang.employeePhone[lang],
      value: data?.phone,
    },
    {
      name: globalLang.region[lang],
      value: data?.area,
    },
    {
      name: globalLang.address[lang],
      value: data?.address,
    },
    {
      name: globalLang.unitType[lang],
      value: data?.unit_type,
    },
    {
      name: globalLang.floor[lang],
      value: data?.floor_number,
    },
    {
      name: globalLang.area[lang],
      value: data?.area_size,
    },
    {
      name: globalLang.roomsNumber[lang],
      value: data?.room_number,
    },
    {
      name: globalLang.bathroomsNumber[lang],
      value: data?.bath_count,
    },
    {
      name: globalLang.finishingType[lang],
      value: data?.complete_type,
    },
    {
      name: globalLang.price[lang],
      value: data?.price,
    },

    {
      name: globalLang.country[lang],
      value: data?.country,
    },
    {
      name: globalLang.governorate[lang],
      value: data?.state,
    },
    {
      name: globalLang.city[lang],
      value: data?.city,
    },
    {
      name: globalLang.neighboring[lang],
      value: data?.part,
    },
    {
      name: globalLang.buildingNumber[lang],
      value: data?.flat_number,
    },
    {
      name: globalLang.notes[lang],
      value: data?.comment,
    },
    {
      name: globalLang.createdAt[lang],
      value: data?.created_at && format(data?.created_at),
    },
  ];
  const userInf = useSelector((state) => state.userInfo.value);

  const checpermissionExistence = (perm) =>
    userInf.user_permissions?.some(({ codename }) => codename == perm);
  if (data) {
    if (
      checpermissionExistence("aqarviewclientdetailsinunits") ||
      ("client" && "phone_client" in data)
    ) {
      if ("client" in data) {
        info.splice(11, 0, {
          name: globalLang.customer[lang],
          value: data?.client,
        });
      }
      if ("phone_client" in data) {
        info.splice(12, 0, {
          name: globalLang.customerPhone[lang],
          value: data?.phone_client,
        });
      }
    }
  }
  const addPictureRef = useRef(null);

  const changePictureRef = useRef(null);
  const thisPic = useRef(null);

  const [pictures, setPictures] = usePropState(data?.images, [data]);

  const [picturePatchRequest, picturePatchResponse] = useRequest({
    path: UNITS,
    method: "patch",
  });

  const dispatch = useDispatch();

  const handleDeletePicture = (id) => {
    const requestBody = filter({
      obj: {
        image_id: id,
      },
      output: "formData",
    });

    picturePatchRequest({
      body: requestBody,
      id: data.id,
      onSuccess: (res) => {
        dispatch({
          type: "units/putItem",
          payload: { id: res.data.id, item: res.data },
        });

        setPictures(res.data.images);
      },
    });
  };

  const handleAddPicture = (e) => {
    const requestBody = filter({
      obj: {
        image: e.target.files[0],
      },
      output: "formData",
    });

    picturePatchRequest({
      body: requestBody,
      id: data.id,
      onSuccess: (res) => {
        dispatch({
          type: "units/putItem",
          payload: { id: res.data.id, item: res.data },
        });

        setPictures(res.data.images);
      },
    });
  };

  const handleChangePicture = ({ id, image }) => {
    const requestBody = filter({
      obj: {
        image_id: id,
        image: image,
      },
      output: "formData",
    });

    picturePatchRequest({
      body: requestBody,
      id: data.id,
      onSuccess: (res) => {
        dispatch({
          type: "units/putItem",
          payload: { id: res.data.id, item: res.data },
        });

        setPictures(res.data.images);
      },
    });
  };

  //===Start====== share logic ===========

  const userInfo = useSelector((state) => state.userInfo.value);

  const message = `متوفر لدى ${
    userInfo?.organization?.name ? userInfo?.organization?.name : "-"
  }
  نوع الوحدة: ${data?.unit_type ? data?.unit_type : "لا يوجد"}
  العنوان: ${data?.address ? data?.address : "لا يوجد"}
  المنطقة: ${data?.area ? data?.area : "لا يوجد"}
  الدور: ${data?.floor_number ? data?.floor_number : "لا يوجد"}
  المساحة: ${data?.area_size ? data?.area_size : "لا يوجد"}
  عدد الغرف: ${data?.room_number ? data?.room_number : "لا يوجد"}
  عدد الحمامات: ${data?.bath_count ? data?.bath_count : "لا يوجد"}
  نوع التشطيب: ${data?.complete_type ? data?.complete_type : "لا يوجد"}
  السعر: ${data?.price ? data?.price : "لا يوجد"}
  البلد: ${data?.country ? data?.country : "لا يوجد"}
  المحافظة: ${data?.state ? data?.state : "لا يوجد"}
  المدينة: ${data?.city ? data?.city : "لا يوجد"}
  رقم العمارة: ${data?.flat_number ? data?.flat_number : "لا يوجد"}\n`;

  //===End====== share logic ===========
  const [opened, setOpened] = useState(false);
  const [actived, setActived] = useState(false);
  console.log(actived);
  const handleOpened = () => {
    setOpened(true);
    setActived(true);
  };
  const handleClosed = () => {
    setOpened(false);
    setActived(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setActived(false);
      }}
      sx={{ width: "50%", mx: "auto", opacity: actived == true ? "0" : "1" }}
    >
      <PermissionsGate permissions={["change_aqarunit"]}>
        <DialogContent>
          <Stack
            direction="row"
            sx={{ width: "100%" }}
            justifyContent="center"
            gap="20px"
          >
            <Box
              component="input"
              type="file"
              multiple={true}
              ref={changePictureRef}
              sx={{ display: "none" }}
              onChange={(e) => {
                handleChangePicture({
                  id: e.target.id,
                  image: e.target.files[0],
                });
              }}
            ></Box>
            {pictures?.length > 0 ? (
              <>
                <Jump>
                  {" "}
                  <Button
                    onClick={handleOpened}
                    variant="contained"
                    style={{ border: "1px solid white" }}
                    sx={{
                      height: 50,
                      width: "auto",
                      position: "relative",
                      zIndex: 1,
                      fontSize: 20,
                      boxShadow: "1px 0px 10px white",
                      p: 2,
                    }}
                  >
                    {lang == "ar" ? " معرض الصور " : " Gallery "}
                    <CollectionsIcon sx={{ ml: 2, fontSize: 50 }} />
                  </Button>
                </Jump>
              </>
            ) : (
              <>
                <Box
                  component="input"
                  type="file"
                  multiple={true}
                  sx={{ display: "none" }}
                  ref={addPictureRef}
                  onChange={handleAddPicture}
                ></Box>

                {pictures?.length < 100 && (
                  <>
                    {picturePatchResponse.isPending && (
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "50%",
                          height: "auto",
                          bgcolor: "rgba(0, 0, 0, 0.2)",
                          zIndex: 1,
                        }}
                      >
                        <CircularProgress sx={{ color: "white" }} />
                      </Stack>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 120,
                        height: 120,
                        bgcolor: "rgba(0, 0, 0, 0.2)",
                        borderRadius: 1.5,
                        cursor: "pointer",
                      }}
                      onClick={() => addPictureRef.current.click()}
                    >
                      <AddIcon sx={{ color: "white" }} />
                    </Box>
                  </>
                )}
              </>
            )}
            {pictures &&
              pictures.map((item, index) => (
                <Badge key={`image ${index}`}>
                  <Dialog
                    hideBackdrop
                    sx={{
                      width: "50%",
                      mx: "auto",
                      display: actived == false && "none ",
                    }}
                    open={opened}
                    onClose={() => {
                      onClose();
                      handleClosed();
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <DialogContent>
                      <Stack
                        direction="column-reverse"
                        justifyContent="center"
                        gap="60px"
                        sx={{
                          mx: "auto",
                          height: "auto",
                          width: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          overflow: "visible",
                        }}
                      >
                        <Typography
                          Component="h3"
                          sx={{
                            p: 1,
                            textAlign: "center",
                            color: "white",
                            cursor: "auto",
                            boxShadow: "0px 0px 3px white",
                            width: "100%",
                          }}
                        >
                          {lang == "ar" ? " العدد الكلي  " : " total Media "}
                          {`( ${index + 1} )`}
                        </Typography>

                        <Box
                          component="input"
                          type="file"
                          multiple={true}
                          ref={changePictureRef}
                          sx={{ display: "none" }}
                          onChange={(e) => {
                            handleChangePicture({
                              id: e.target.id,
                              image: e.target.files[0],
                            });
                          }}
                        ></Box>
                        {pictures &&
                          pictures.map((item, index) => (
                            <Badge
                              badgeContent={
                                <IconButton
                                  sx={{
                                    bgcolor: "red",
                                    position: "relative",
                                    "&:hover": {
                                      bgcolor: "red",
                                    },
                                  }}
                                  onClick={() => handleDeletePicture(item.id)}
                                  key={`image ${index}`}
                                >
                                  <DeleteIcon sx={{ color: "white" }} />
                                </IconButton>
                              }
                            >
                              {picturePatchResponse.isPending && (
                                <Stack
                                  justifyContent="center"
                                  alignItems="center"
                                  sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "50%",
                                    height: "auto",
                                    bgcolor: "rgba(0, 0, 0, 0.2)",
                                    zIndex: 1,
                                  }}
                                >
                                  <CircularProgress sx={{ color: "white" }} />
                                </Stack>
                              )}

                              <a
                                href={item.image}
                                target="_blank"
                                style={{
                                  position: "relative",
                                  zIndex: "2",
                                  top: "0",
                                  right: "0",
                                  marginTop: "-17px",

                                  color: "red",
                                  fontWeight: "bold",
                                  fontSize: "40px",
                                }}
                              >
                                <OpenInNewOutlinedIcon
                                  sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: "40px",
                                  }}
                                />
                              </a>
                              <Zoom>
                                <Avatar
                                  variant="rounded"
                                  src={item.image}
                                  sx={{
                                    width: "250px",
                                    height: "250px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    changePictureRef.current.id = item.id;
                                    changePictureRef.current.click();
                                  }}
                                >
                                  <video
                                    className="VideoInput_video"
                                    controls
                                    src={item.image}
                                  />
                                </Avatar>
                              </Zoom>
                            </Badge>
                          ))}
                        <Box
                          component="input"
                          type="file"
                          multiple={true}
                          sx={{ display: "none" }}
                          ref={addPictureRef}
                          onChange={handleAddPicture}
                        ></Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: 120,
                            height: 120,
                            bgcolor: "rgba(0, 0, 0, 0.2)",
                            borderRadius: 1.5,
                            cursor: "pointer",
                          }}
                          onClick={() => addPictureRef.current.click()}
                        >
                          <AddIcon sx={{ color: "white" }} />
                        </Box>
                        <Box
                          component="input"
                          type="file"
                          multiple={true}
                          sx={{ display: "none" }}
                          ref={addPictureRef}
                          onChange={handleAddPicture}
                        ></Box>
                        <Button
                          sx={{
                            position: "absolute",
                            top: "15px",
                            right: "15px",

                            width: "20px",
                            height: "20px",

                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                          onClick={() => setActived(false)}
                        >
                          <ArrowBackIcon sx={{ color: "white" }} />
                        </Button>
                      </Stack>
                    </DialogContent>
                  </Dialog>
                </Badge>
              ))}
          </Stack>
        </DialogContent>
      </PermissionsGate>
      <DialogInfoWindow information={info} />
      <DialogContent>
        <Stack direction="row" justifyContent="center">
          <FacebookShareButton
            quote={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <FacebookIcon size={32} round={true} />
            </IconButton>
          </FacebookShareButton>
          <WhatsappShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <WhatsappIcon size={32} round={true} />
            </IconButton>
          </WhatsappShareButton>
          <LinkedinShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <LinkedinIcon size={32} round={true} />
            </IconButton>
          </LinkedinShareButton>
          <TelegramShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <TelegramIcon size={32} round={true} />
            </IconButton>
          </TelegramShareButton>
          <PinterestShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <PinterestIcon size={32} round={true} />
            </IconButton>
          </PinterestShareButton>
          <VKShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <VKIcon size={32} round={true} />
            </IconButton>
          </VKShareButton>
          <OKShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <OKIcon size={32} round={true} />
            </IconButton>
          </OKShareButton>
          <RedditShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <RedditIcon size={32} round={true} />
            </IconButton>
          </RedditShareButton>
          <TumblrShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <TumblrIcon size={32} round={true} />
            </IconButton>
          </TumblrShareButton>
          <LivejournalShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <LivejournalIcon size={32} round={true} />
            </IconButton>
          </LivejournalShareButton>
          <TwitterShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <TwitterIcon size={32} round={true} />
            </IconButton>
          </TwitterShareButton>
          <MailruShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <MailruIcon size={32} round={true} />
            </IconButton>
          </MailruShareButton>
          <WorkplaceShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <WorkplaceIcon size={32} round={true} />
            </IconButton>
          </WorkplaceShareButton>
          <ViberShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <ViberIcon size={32} round={true} />
            </IconButton>
          </ViberShareButton>
          <LineShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <LineIcon size={32} round={true} />
            </IconButton>
          </LineShareButton>
          <EmailShareButton
            title={message}
            url={`${
              userInfo.organization.url
                ? userInfo.organization.url
                : globalLang.unknownLink[lang]
            }`}
          >
            <IconButton>
              <EmailIcon size={32} round={true} />
            </IconButton>
          </EmailShareButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
