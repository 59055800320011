import React, { useState } from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import Form, {
  InputField,
  NumberField,
  PhoneField,
  PictureField,
  SelectField,
  TextareaField,
} from "../../features/form";
import useControls from "../../hooks/useControls";
import { MenuItem, TextField } from "@mui/material";
import useRequest from "../../hooks/useRequest";
import { COUNTRY_FILTER, STATES, STATE_CITIES, UNITS } from "../../data/APIs";
import filter from "../../utils/ClearNull";
import { useSelector } from "react-redux";

const AddUnits = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const addUnitsLang = useSelector((state) => state.lang.value.addUnits);
  const globalLang = useSelector((state) => state.lang.value.global);
  const floors = [
    {
      name: "البدروم",
      value: "g",
    },
    {
      name: "الدور الأرضي",
      value: "0",
    },
    {
      name: "1",
      value: "1",
    },
    {
      name: "2",
      value: "1",
    },

    {
      name: "3",
      value: "3",
    },
    {
      name: "4",
      value: "4",
    },
    {
      name: "5",
      value: "5",
    },
    {
      name: "6",
      value: "6",
    },
    {
      name: "7",
      value: "7",
    },
    {
      name: "8",
      value: "8",
    },

    {
      name: "9",
      value: "9",
    },
    {
      name: "10",
      value: "10",
    },
    {
      name: "11",
      value: "11",
    },
    {
      name: "12",
      value: "12",
    },
    {
      name: "13",
      value: "13",
    },
    {
      name: "14",
      value: "14",
    },
    {
      name: "15",
      value: "15",
    },
    {
      name: "16",
      value: "16",
    },
    {
      name: "17",
      value: "17",
    },
    {
      name: "18",
      value: "18",
    },
    {
      name: "19",
      value: "19",
    },
    {
      name: "20",
      value: "20",
    },
  ];
  ///////////////////////////////
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "country",
      value: "",
      isRequired: false,
    },
    {
      control: "governorate",
      value: "",
      isRequired: false,
    },
    {
      control: "city",
      value: "",
      isRequired: false,
    },
    {
      control: "district",
      value: "",
      isRequired: false,
    },
    {
      control: "near",
      value: "",
      isRequired: false,
    },
    {
      control: "address",
      value: "",
      isRequired: false,
    },
    {
      control: "number",
      value: "",
      isRequired: false,
    },
    {
      control: "type",
      value: "",
      isRequired: false,
    },
    {
      control: "sale",
      value: "",
      isRequired: false,
    },
    {
      control: "floor",
      value: "",
      isRequired: false,
    },
    {
      control: "area",
      value: "",
      isRequired: false,
    },
    {
      control: "rooms",
      value: "",
      isRequired: false,
    },
    {
      control: "bathrooms",
      value: "",
      isRequired: false,
    },
    {
      control: "genre",
      value: "",
      isRequired: false,
    },
    {
      control: "price",
      value: "",
      isRequired: false,
    },
    {
      control: "client",
      value: "",
      isRequired: true,
    },
    {
      control: "phone",
      value: "",
      isRequired: true,
    },
    {
      control: "countryCode",
      value: "",
      isRequired: true,
    },
    {
      control: "notes",
      value: "",
      isRequired: false,
    },
    {
      control: "pictures",
      value: [],
      isRequired: false,
    },
  ]);

  const [unitPostRequest, unitPostResponse] = useRequest({
    path: UNITS,
    method: "post",
    successMessage: "تم إضافة وحدة جديدة بنجاح",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = filter({
        obj: {
          address: controls.address,
          area: controls.district,
          floor_number: controls.floor,
          room_number: controls.rooms,
          bath_count: controls.bathrooms,
          price: controls.price,
          comment: controls.notes,
          unit_type: controls.type,
          area_size: controls.area,
          complete_type: controls.genre,
          sales_type: controls.sale,
          phone_client: controls.countryCode + controls.phone,
          client: controls.client,
          country: controls.country,
          state: controls.governorate,
          city: controls.city,
          part: controls.near,
          flat_number: controls.number,
          image: [...controls.pictures],
        },
        output: "formData",
      });

      if (
        Object.keys(
          filter({
            obj: {
              address: controls.address,
              area: controls.district,
              floor_number: controls.floor,
              room_number: controls.rooms,
              bath_count: controls.bathrooms,
              price: controls.price,
              comment: controls.notes,
              unit_type: controls.type,
              area_size: controls.area,
              complete_type: controls.genre,
              sales_type: controls.sale,
              phone_client: controls.countryCode + controls.phone,
              client: controls.client,
              country: controls.country,
              state: controls.governorate,
              city: controls.city,
              part: controls.near,
              flat_number: controls.number,
              image: [...controls.pictures],
            },
          })
        ).length <= 1
      )
        return;

      unitPostRequest({
        body: requestBody,
        onSuccess: () => resetControls(),
      }).then((res) => {
        const response = res?.response?.data;
        const responseBody = filter({
          obj: {
            address: response?.address,
            district: response?.area,
            floor: response?.floor_number,
            rooms: response?.room_number,
            bathrooms: response?.bath_count,
            price: response?.price,
            notes: response?.comment,
            type: response?.unit_type,
            area: response?.area_size,
            genre: response?.complete_type,
            sale: response?.sales_type,
            phone: response?.phone_client,
            client: response?.client,
            country: response?.country,
            governorate: response?.state,
            city: response?.city,
            near: response?.part,
            number: response?.flat_number,
          },
        });

        setInvalid(responseBody);
      });
    });
  };

  //===Start===== Get Countries logic =========
  const [countriesData, setCountriesData] = useState([]);

  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  const getCountries = () => {
    countriesGetRequest({
      onSuccess: (res) => {
        setCountriesData(res.data.data);
      },
    });
  };

  const [governoratesData, setGovernoratesData] = useState([]);

  const [statesGetRequest, statesGetResponse] = useRequest({
    path: STATES,
    method: "post",
  });

  const getGovernorates = () => {
    statesGetRequest({
      body: {
        country: controls.country,
      },
      onSuccess: (res) => {
        setGovernoratesData(res.data.data);
      },
    });
  };

  //===End===== Get Countries logic =========

  return (
    <Wrapper>
      <Breadcrumbs
        path={[globalLang.units[lang], addUnitsLang.addUnits[lang]]}
      />
      <Form
        component="form"
        onSubmit={handleSubmit}
        childrenProps={{
          saveBtn: {
            disabled: unitPostResponse.isPending,
          },
          closeBtn: {
            disabled: unitPostResponse.isPending,
          },
        }}
      >
        <SelectField
          label={globalLang.country[lang]}
          placeholder={globalLang.country[lang]}
          onOpen={getCountries}
          isPending={countriesGetResponse.isPending}
          required={required.includes("country")}
          value={controls.country}
          onChange={(e) => {
            setControl("country", e.target.value);
            setControl("governorate", "");
            setControl("city", "");
          }}
          error={Boolean(invalid.country)}
          helperText={invalid.country}
        >
          {countriesData.map((country, index) => (
            <MenuItem value={country} key={`country ${index}`}>
              {country}
            </MenuItem>
          ))}
        </SelectField>
        <SelectField
          label={globalLang.governorate[lang]}
          placeholder={globalLang.governorate[lang]}
          disabled={!Boolean(controls.country)}
          onOpen={getGovernorates}
          isPending={statesGetResponse.isPending}
          required={required.includes("governorate")}
          value={controls.governorate}
          onChange={(e) => {
            setControl("governorate", e.target.value);
            setControl("city", "");
          }}
          error={Boolean(invalid.governorate)}
          helperText={invalid.governorate}
        >
          {governoratesData.map((governorate, index) => (
            <MenuItem value={governorate} key={`state ${index}`}>
              {governorate}
            </MenuItem>
          ))}
        </SelectField>
        <InputField
          label={globalLang.city[lang]}
          placeholder={globalLang.city[lang]}
          required={required.includes("city")}
          value={controls.city}
          onChange={(e) => setControl("city", e.target.value)}
          error={Boolean(invalid.city)}
          helperText={invalid.city}
        />
        <InputField
          label={globalLang.region[lang]}
          placeholder={globalLang.region[lang]}
          required={required.includes("district")}
          value={controls.district}
          onChange={(e) => setControl("district", e.target.value)}
          error={Boolean(invalid.district)}
          helperText={invalid.district}
        />
        <InputField
          label={globalLang.neighboring[lang]}
          placeholder={globalLang.neighboring[lang]}
          required={required.includes("near")}
          value={controls.near}
          onChange={(e) => setControl("near", e.target.value)}
          error={Boolean(invalid.near)}
          helperText={invalid.near}
        />
        <InputField
          label={globalLang.address[lang]}
          placeholder={globalLang.address[lang]}
          required={required.includes("address")}
          value={controls.address}
          onChange={(e) => setControl("address", e.target.value)}
          error={Boolean(invalid.address)}
          helperText={invalid.address}
        />
        <NumberField
          label={globalLang.buildingNumber[lang]}
          placeholder={globalLang.buildingNumber[lang]}
          required={required.includes("number")}
          value={controls.number}
          onChange={(e) => setControl("number", e.target.value)}
          error={Boolean(invalid.number)}
          helperText={invalid.number}
        />
        <InputField
          label={globalLang.unitType[lang]}
          placeholder={globalLang.unitType[lang]}
          required={required.includes("type")}
          value={controls.type}
          onChange={(e) => setControl("type", e.target.value)}
          error={Boolean(invalid.type)}
          helperText={invalid.type}
        />
        <SelectField
          label={globalLang.saleType[lang]}
          placeholder={globalLang.saleType[lang]}
          required={required.includes("sale")}
          value={controls.sale}
          renderValue={(selected) =>
            saleTypes.find((saleType) => saleType.value === selected).name
          }
          onChange={(e) => setControl("sale", e.target.value)}
          error={Boolean(invalid.sale)}
          helperText={invalid.sale}
        >
          {saleTypes.map((saleType, index) => (
            <MenuItem value={saleType.value} key={`saleType ${index}`}>
              {saleType.name}
            </MenuItem>
          ))}
          <MenuItem value="">..</MenuItem>
        </SelectField>
        {/* <NumberField
          label={globalLang.floor[lang]}
          placeholder={globalLang.floor[lang]}
          required={required.includes("floor")}
          value={controls.floor}
          onChange={(e) => setControl("floor", e.target.value)}
          error={Boolean(invalid.floor)}
          helperText={invalid.floor}
        /> */}
        <SelectField
          label={globalLang.floor[lang]}
          placeholder={globalLang.floor[lang]}
          value={controls.floor}
          renderValue={(selected) =>
            floors.find((f) => f.value === selected).name
          }
          onChange={(e) => setControl("floor", e.target.value)}
        >
          {floors.map((floor, index) => (
            <MenuItem value={floor.value} key={` ${index}`}>
              {floor.name}
            </MenuItem>
          ))}
        </SelectField>
        <NumberField
          label={globalLang.area[lang]}
          placeholder={globalLang.area[lang]}
          suffix=" متر"
          isAllowed={({ value }) => value.length <= 5}
          required={required.includes("area")}
          value={controls.area}
          onChange={(e) =>
            setControl("area", e.target.value.replace("متر", "").trim())
          }
          error={Boolean(invalid.area)}
          helperText={invalid.area}
        />
        <SelectField
          label={globalLang.roomsNumber[lang]}
          placeholder={globalLang.roomsNumber[lang]}
          required={required.includes("rooms")}
          value={controls.rooms}
          onChange={(e) => setControl("rooms", e.target.value)}
          error={Boolean(invalid.rooms)}
          helperText={invalid.rooms}
        >
          {rooms.map((room, index) => (
            <MenuItem value={room} key={`room ${index}`}>
              {room}
            </MenuItem>
          ))}
          <MenuItem value="">..</MenuItem>
        </SelectField>
        <SelectField
          label={globalLang.bathroomsNumber[lang]}
          placeholder={globalLang.bathroomsNumber[lang]}
          required={required.includes("bathrooms")}
          value={controls.bathrooms}
          onChange={(e) => setControl("bathrooms", e.target.value)}
          error={Boolean(invalid.bathrooms)}
          helperText={invalid.bathrooms}
        >
          {bathrooms.map((bathroom, index) => (
            <MenuItem value={bathroom} key={`bathroom ${index}`}>
              {bathroom}
            </MenuItem>
          ))}
          <MenuItem value="">..</MenuItem>
        </SelectField>
        <InputField
          label={globalLang.finishingType[lang]}
          placeholder={globalLang.finishingType[lang]}
          required={required.includes("genre")}
          value={controls.genre}
          onChange={(e) => setControl("genre", e.target.value)}
          error={Boolean(invalid.genre)}
          helperText={invalid.genre}
        />
        <InputField
          label={globalLang.price[lang]}
          placeholder={globalLang.price[lang]}
          required={required.includes("price")}
          value={controls.price}
          onChange={(e) => setControl("price", e.target.value)}
          error={Boolean(invalid.price)}
          helperText={invalid.price}
        />
        <InputField
          label={globalLang.customerName[lang]}
          placeholder={globalLang.customerName[lang]}
          required={required.includes("client")}
          value={controls.client}
          onChange={(e) => setControl("client", e.target.value)}
          error={Boolean(invalid.client)}
          helperText={invalid.client}
        />
        <PhoneField
          label={globalLang.customerPhone[lang]}
          placeholder={globalLang.customerPhone[lang]}
          selectProps={{
            value: controls.countryCode,
            onChange: (e) => {
              setControl("countryCode", e.target.value);
            },
          }}
          requiredCode
          required={required.includes("phone")}
          value={controls.phone}
          onChange={(e) => setControl("phone", e.target.value)}
          error={Boolean(invalid.phone)}
          helperText={invalid.phone}
        />

        <PictureField
          label={globalLang.picture[lang]}
          placeholder={globalLang.picture[lang]}
          onChange={(e) => {
            if (Object.keys(e.target.files).length < 1) {
              alert("قم باضافه صوره  ");
              e.target.value = [];
              return;
            }
            setControl("pictures", [
              ...Object.keys(e.target.files).map((key) => e.target.files[key]),
            ]);
          }}
        />
        <TextareaField
          label={globalLang.notes[lang]}
          placeholder={globalLang.notes[lang]}
          multiline
          variant="standard"
          required={required.includes("notes")}
          value={controls.notes}
          onChange={(e) => setControl("notes", e.target.value)}
          error={Boolean(invalid.notes)}
          helperText={invalid.notes}
          sx={{ backgroundColor: "primary" }}
        />
      </Form>
      {unitPostResponse.successAlert}
      {unitPostResponse.failAlert}
    </Wrapper>
  );
};

export default AddUnits;

const bathrooms = [
  ...Array(10)
    .fill(0)
    .map((_, index) => index + 1),
];

const rooms = [
  ...Array(10)
    .fill(0)
    .map((_, index) => index + 1),
];

const saleTypes = [
  {
    name: "بيع",
    value: "sale",
  },
  {
    name: "إيجار",
    value: "rent",
  },
];
