import React from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import TextareaField from "./TextareaField";
import { Box } from "@mui/system";
import { useRef } from "react";
import { useState } from "react";

const PictureField = ({
  multiple = false,
  label,
  placeholder,

  onChange = () => {},
  ...rest
}) => {
  const ref = useRef(null);
  const [value, setValue] = useState({});

  return (
    <Box>
      <input
        type="file"
        accept="/*"
        multiple={true}
        ref={ref}
        onChange={(e) => {
          setValue(e.target.files);
          onChange(e);
        }}
        style={{ display: "none" }}
      />

      <TextField
        variant="standard"
        label={label}
        multiline
        rows="4"
        placeholder={""}
        sx={{
          "& .MuiInputBase-input": {
            padding: "10px 12px",
            border: "0",
            cursor: "pointer",
          },
        }}
        value={Object.keys(value)
          .map((picture) => value[picture]?.name)
          .join(" - ")}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={(e) => ref.current.click()}>
                <AddPhotoAlternateIcon
                  color="primary"
                  sx={{ width: "150px", height: "150px" }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </Box>
  );
};

export default PictureField;
