import { createSlice } from "@reduxjs/toolkit";

export const ImportClientsSlice = createSlice({
  name: "ImportClients",
  initialState: {
    value: {
      filesProgress: null,
    },
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload;
    },
    setProgress: (state, action) => {
      state.value.filesProgress = action.payload;
    },
    reset: (state) => {
      state.value = {
        filesProgress: null,
      };
    },
  },
});

export default ImportClientsSlice.reducer;
