import React from "react";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import GroupsIcon from "@mui/icons-material/Groups";
import StoreIcon from "@mui/icons-material/Store";
import BadgeIcon from "@mui/icons-material/Badge";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import ApartmentIcon from "@mui/icons-material/Apartment";

import Home from "../pages/Home";

import AddClients from "../pages/client/AddClients";
import AddStatus from "../pages/client/AddStatus";
import DeletedClients from "../pages/client/DeletedClients";
import ExportClients from "../pages/client/ExportClients";
import ExportRegistry from "../pages/client/ExportRegistry";
import ImportClients from "../pages/client/ImportClients";
import ImportRegistry from "../pages/client/ImportRegistry";
import Overview from "../pages/client/Overview";
import ViewClients from "../pages/client/ViewClients";
import ViewStatus from "../pages/client/ViewStatus";
import AddProjects from "../pages/project/AddProjects";
import ViewProjects from "../pages/project/ViewProjects";
import { InfoDialog } from "../pages/client/ViewClients";
import AddEmployees from "../pages/employee/AddEmployees";
import AddJobs from "../pages/employee/AddJobs";
import ViewEmployees from "../pages/employee/ViewEmployees";
import ViewJobs from "../pages/employee/ViewJobs";

import AddChannels from "../pages/channel/AddChannels";
import ViewChannels from "../pages/channel/ViewChannels";

import AddUnits from "../pages/unit/AddUnits";
import ViewUnits from "../pages/unit/ViewUnits";

import Setting from "../pages/control/Setting";


const pages = [
  {
    name: {
      ar: "الرئيسية",
      en: "Home",
    },
    icon: <AutoAwesomeMosaicIcon />,
    path: "/",
    element: <Home />,
    permitted: [],
  },
  {
    name: {
      ar: "الموظفين",
      en: "Employees",
    },
    icon: <BadgeIcon />,
    path: "/employees",
    subtabs: [
      {
        // name: {
        //   ar: "إضافة وظيفة موظف",
        //   en: "Add an employee job",
        // },
        name_en: "Add an employee job",
        name: "إضافة وظيفة موظف",
        path: "/create-jobs",
        element: <AddJobs />,
        permitted: ["add_aqarjob"],
      },
      {
        // name: {
        //   ar: "عرض وظائف الموظفين",
        //   en: "View employee jobs",
        // },
        name_en: "View employee jobs",
        name: "عرض وظائف الموظفين",
        path: "/view-jobs",
        element: <ViewJobs />,
        permitted: ["view_aqarjob"],
      },
      {
        // name: {
        //   ar: "إضافة موظف",
        //   en: "Add an employee",
        // },
        name_en: "Add an employee",
        name: "إضافة موظف",
        path: "/create-employees",
        element: <AddEmployees />,
        permitted: ["add_aqaremployee"],
      },
      {
        // name: {
        //   ar: "عرض الموظفين",
        //   en: "View the staff",
        // },
        name_en: "View the staff",
        name: "عرض الموظفين",
        path: "/view-employees",
        element: <ViewEmployees />,
        permitted: ["view_aqaremployee"],
      },
    ],
  },
  {
    name: {
      ar: "العملاء",
      en: "Customers",
    },
    icon: <GroupsIcon />,
    path: "/clients",
    subtabs: [
      {
        // name: {
        //   ar: "الإحصائيات",
        //   en: "statistics",
        // },
        name_en: "statistics",
        name: "الإحصائيات",
        path: "/overview",
        element: <Overview />,
        permitted: ["aqarstatistics"],
      },
      {
        // name: {
        //   ar: "إضافة عميل جديد",
        //   en: "Add a new customer",
        // },
        name_en: "Add a new customer",
        name: "إضافة عميل جديد",
        path: "/create-client",
        element: <AddClients />,
        permitted: ["add_aqarclient"],
      },

      {
        // name: {
        //   ar: "جميع العملاء",
        //   en: "all clients",
        // },
        name_en: "all clients",
        name: "جميع العملاء",
        path: "/view-clients",
        element: <ViewClients />,
        permitted: ["view_aqarclient"],
      },
      {
        // name: {
        //   ar: "العملاء الحذوفة",
        //   en: "Deleted clients",
        // },
        name_en: "Deleted clients",
        name: "العملاء المحذوفين",
        path: "/deleted-clients",
        element: <DeletedClients />,
        permitted: ["delete_aqarclient"],
      },
      {
        // name: {
        //   ar: "إضافة حالة عميل",
        //   en: "Add a customer case",
        // },
        name_en: "Add a customer case",
        name: "إضافة حالة عميل",
        path: "/create-status",
        element: <AddStatus />,
        permitted: ["add_aqarevent"],
      },
      {
        // name: {
        //   ar: "عرض حالات العملاء",
        //   en: "View customer cases",
        // },
        name_en: "View customer cases",
        name: "عرض حالات العملاء",
        path: "/view-status",
        element: <ViewStatus />,
        permitted: ["view_aqarevent"],
      },
      {
        // name: {
        //   ar: "إستيراد عملاء",
        //   en: "import clients",
        // },
        name_en: "import clients",
        name: "إستيراد عملاء",
        path: "/import-clients",
        element: <ImportClients />,
        permitted: ["aqarimport_file"],
      },
      {
        // name: {
        //   ar: "تصدير عملاء",
        //   en: "export clients",
        // },
        name_en: "export clients",
        name: "تصدير عملاء",
        path: "/export-clients",
        element: <ExportClients />,
        permitted: ["aqarexport_file"],
      },
      {
        // name: {
        //   ar: "سجل الإستيراد",
        //   en: "import log",
        // },
        name_en: "export log",
        name: "سجل التصدير",
        path: "/export-records",
        element: <ImportRegistry />,
        permitted: ["view_aqarimportexportfiels"],
      },
      {
        // name: {
        //   ar: "سجل التصدير",
        //   en: "export record",
        // },
        name_en: "import record",
        name: "سجل الإستيراد",
        path: "/import-records",
        element: <ExportRegistry />,
        permitted: ["view_aqarimportexportfiels"],
        
      },
    ],
  },
  {
    name: {
      ar: "المشاريع",
      en: "Projects",
    },
    icon: <StoreIcon />,
    path: "/projects",
    subtabs: [
      {
        // name: {
        //   ar: "إضافة مشروع",
        //   en: "add project",
        // },
        name_en: "add project",
        name: "إضافة مشروع",
        path: "/create-projects",
        element: <AddProjects />,
        permitted: ["add_aqarproject"],
      },
      {
        // name: {
        //   ar: "عرض المشاريع",
        //   en: "View projects",
        // },
        name_en: "View projects",
        name: "عرض المشاريع",
        path: "/view-projects",
        element: <ViewProjects />,
        permitted: ["view_aqarproject"],
      },
    ],
  },
  {
    name: {
      ar: "القنوات",
      en: "Channels",
    },
    icon: <AddCircleIcon />,
    path: "/channels",
    subtabs: [
      {
        // name: {
        //   ar: "إضافة قناة",
        //   en: "Add channel",
        // },
        name_en: "Add channel",
        name: "إضافة قناة",
        path: "/create-channels",
        element: <AddChannels />,
        permitted: ["add_aqarchannel"],
      },
      {
        // name: {
        //   ar: "عرض القنوات",
        //   en: "View channels",
        // },
        name_en: "View channels",
        name: "عرض القنوات",
        path: "/view-channels",
        element: <ViewChannels />,
        permitted: ["view_aqarchannel"],
      },
    ],
  },
  {
    name: {
      ar: "الوحدات",
      en: "Units",
    },
    icon: <ApartmentIcon />,
    path: "/units",
    subtabs: [
      {
        // name: {
        //   ar: "إضافة وحدة",
        //   en: "add unit",
        // },
        name_en: "add unit",
        name: "إضافة وحدة",
        path: "/create-units",
        element: <AddUnits />,
        permitted: ["add_aqarunit"],
      },
      {
        // name: {
        //   ar: "عرض الوحدات",
        //   en: "Show units",
        // },
        name_en: "Show units",
        name: "عرض الوحدات",
        path: "/view-units",
        element: <ViewUnits />,
        permitted: ["view_aqarunit"],
      },
    ],
  },
  null,
  {
    name: {
      ar: "التحكم",
      en: "Control",
    },

    icon: <SettingsIcon />,
    path: "/control",
    subtabs: [
      {
        // name: {
        //   ar: "تغيير الرقم السري",
        //   en: "Change Password",
        // },
        name_en: "Setting",
        name: "الاعدادات",
        path: "/setting",
        element: <Setting />,
        permitted: [],
      },
    ],
  },
  null,
];

export default pages;
